import React, { useState, useCallback } from 'react';
import { AlertCircle } from 'lucide-react';
import { GroomerCard } from './GroomerCard';
import { searchGroomers } from '../services/api/places';
import { geocodeZipCode } from '../services/api/geocoding';
import type { PlaceResult } from '../types/places';
import { ERROR_MESSAGES } from '../utils/constants';

export function LocationFinder() {
  const [zipCode, setZipCode] = useState('');
  const [results, setResults] = useState<PlaceResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);

  const handleSearch = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!zipCode.trim()) return;

    setLoading(true);
    setError(null);
    setResults([]);
    setAddress(null);

    try {
      const location = await geocodeZipCode(zipCode);
      setAddress(location.formatted_address);

      const groomers = await searchGroomers(location.lat, location.lng);
      setResults(groomers);
      
      if (groomers.length === 0) {
        setError('No pet groomers found in this area. Try a different ZIP code.');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : ERROR_MESSAGES.PLACES_FAILED);
    } finally {
      setLoading(false);
    }
  }, [zipCode]);

  const handleZipCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 5);
    setZipCode(value);
  }, []);

  return (
    <div className="space-y-8">
      <form onSubmit={handleSearch} className="max-w-md mx-auto">
        <div className="flex gap-4">
          <input
            type="text"
            placeholder="Enter ZIP code"
            className="flex-1 p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={zipCode}
            onChange={handleZipCodeChange}
            pattern="[0-9]{5}"
            maxLength={5}
            required
            aria-label="ZIP code"
          />
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={loading || zipCode.length !== 5}
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>
      </form>

      <div className="space-y-4">
        {loading && (
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-4 text-gray-600">Searching for groomers in your area...</p>
          </div>
        )}

        {error && (
          <div className="text-center py-8 px-4 bg-red-50 rounded-lg">
            <div className="flex items-center justify-center gap-2 text-red-600 mb-2">
              <AlertCircle className="w-5 h-5" />
              <span className="font-semibold">Error</span>
            </div>
            <p className="text-red-700 mb-4">{error}</p>
          </div>
        )}

        {!loading && !error && results.length > 0 && (
          <>
            {address && (
              <p className="text-center text-gray-600 mb-4">
                Showing results near {address}
              </p>
            )}
            <div className="grid gap-4">
              {results.map((result) => (
                <GroomerCard key={result.place_id} groomer={result} />
              ))}
            </div>
          </>
        )}

        {!loading && !error && address && results.length === 0 && (
          <div className="text-center py-8 bg-gray-50 rounded-lg">
            <p className="text-gray-600">No groomers found near {address}. Try a different ZIP code.</p>
          </div>
        )}
      </div>
    </div>
  );
}