import axios from 'axios';
import type { PlaceResult } from '../../types/places';
import { API_ENDPOINTS, ERROR_MESSAGES } from '../../utils/constants';
import { handleApiError } from '../../utils/apiUtils';
import { isValidCoordinates } from '../../utils/validation';

interface PlacesResponse {
  results: PlaceResult[];
  status: string;
}

export async function searchGroomers(lat: number, lng: number): Promise<PlaceResult[]> {
  if (!isValidCoordinates(lat, lng)) {
    throw new Error(ERROR_MESSAGES.INVALID_COORDS);
  }

  try {
    const response = await axios.post<PlacesResponse>(
      API_ENDPOINTS.PLACES_SEARCH, 
      { lat, lng }
    );

    if (!response.data?.results) {
      return [];
    }

    return response.data.results;
  } catch (error) {
    throw handleApiError(error, ERROR_MESSAGES.PLACES_FAILED);
  }
}