import React from 'react';
import { MapPin, Phone, Star } from 'lucide-react';
import { PlaceResult } from '../types/places';

interface GroomerCardProps {
  groomer: PlaceResult;
}

export function GroomerCard({ groomer }: GroomerCardProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-xl font-semibold mb-2">{groomer.name}</h3>
          <div className="flex items-center gap-2 text-gray-600 mb-2">
            <MapPin className="w-4 h-4" />
            <span>{groomer.formatted_address}</span>
          </div>
          {groomer.rating && (
            <div className="flex items-center gap-1">
              <Star className="w-4 h-4 text-yellow-500" />
              <span className="font-medium">{groomer.rating}</span>
              <span className="text-gray-500">
                ({groomer.user_ratings_total} reviews)
              </span>
            </div>
          )}
        </div>
        {groomer.formatted_phone_number && (
          <a
            href={`tel:${groomer.formatted_phone_number}`}
            className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
          >
            <Phone className="w-5 h-5" />
            <span>{groomer.formatted_phone_number}</span>
          </a>
        )}
      </div>
    </div>
  );
}