import React from 'react';
import { Scissors, Mail } from 'lucide-react';
import { LocationFinder } from './components/LocationFinder';
import { BlogPost } from './components/BlogPost';

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Header */}
      <header className="bg-white shadow-lg">
        <div className="container mx-auto px-4 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Scissors className="w-8 h-8 text-blue-600" />
              <h1 className="text-2xl font-bold text-gray-800">BestDogGroomerNearMe</h1>
            </div>
            <nav className="hidden md:flex gap-6">
              <a href="#find-groomer" className="text-gray-600 hover:text-blue-600">Find a Groomer</a>
              <a href="#tips" className="text-gray-600 hover:text-blue-600">Grooming Tips</a>
              <a href="#about" className="text-gray-600 hover:text-blue-600">About</a>
            </nav>
          </div>
        </div>
      </header>

      <main>
        {/* Hero Section */}
        <section className="relative bg-blue-700 text-white py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Find The Best Dog Groomer Near You
              </h1>
              <p className="text-xl mb-8">
                Connect with top-rated professional dog groomers in your area
              </p>
              <a
                href="#find-groomer"
                className="inline-flex items-center gap-2 bg-white text-blue-700 px-8 py-3 rounded-full font-semibold hover:bg-blue-50 transition-colors"
              >
                <Scissors className="w-5 h-5" />
                Find Your Perfect Groomer
              </a>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent to-blue-50"></div>
        </section>

        {/* Location Finder Section */}
        <section id="find-groomer" className="py-16">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold text-center mb-12">
                Find Local Dog Groomers
              </h2>
              <LocationFinder />
            </div>
          </div>
        </section>

        {/* Blog Section */}
        <section id="tips" className="py-16 bg-white">
          <div className="container mx-auto px-4">
            <BlogPost />
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">BestDogGroomerNearMe.com</h3>
              <p className="text-gray-400">
                Connecting pet owners with professional groomers since 2024
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="#find-groomer" className="text-gray-400 hover:text-white">Find a Groomer</a></li>
                <li><a href="#tips" className="text-gray-400 hover:text-white">Grooming Tips</a></li>
                <li><a href="#about" className="text-gray-400 hover:text-white">About Us</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Contact</h3>
              <p className="text-gray-400 flex items-center gap-2">
                <Mail className="w-5 h-5" />
                <a href="mailto:support@bestdoggroomernearme.com" className="hover:text-white">
                  support@bestdoggroomernearme.com
                </a>
              </p>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} BestDogGroomerNearMe.com. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;