import { AxiosError } from 'axios';

export function handleApiError(error: unknown, defaultMessage: string): Error {
  if (error instanceof Error) {
    return error;
  }
  
  if (isAxiosError(error)) {
    const message = error.response?.data?.error || 
                   error.response?.data?.message || 
                   error.message || 
                   defaultMessage;
    return new Error(message);
  }

  return new Error(defaultMessage);
}

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError === true;
}