export const API_ENDPOINTS = {
  GEOCODE: '/api/geocode',
  PLACES_SEARCH: '/api/places-search'
} as const;

export const API_CONFIG = {
  SEARCH_RADIUS_METERS: 10000,
  PLACE_TYPE: 'establishment',
  SEARCH_KEYWORD: 'dog groomer'
} as const;

export const ERROR_MESSAGES = {
  INVALID_ZIP: 'ZIP code is required',
  INVALID_COORDS: 'Valid latitude and longitude are required',
  GEOCODE_FAILED: 'Failed to geocode ZIP code',
  PLACES_FAILED: 'Failed to fetch nearby groomers',
  INVALID_RESPONSE: 'Invalid response from service',
  SERVICE_ERROR: 'Service configuration error'
} as const;