export function isValidZipCode(zipCode: string): boolean {
  return Boolean(zipCode?.trim() && /^\d{5}$/.test(zipCode.trim()));
}

export function isValidCoordinates(lat: number, lng: number): boolean {
  return (
    typeof lat === 'number' &&
    typeof lng === 'number' &&
    !isNaN(lat) &&
    !isNaN(lng) &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
}

export function validateGeocodeResponse(data: unknown): boolean {
  if (!data || typeof data !== 'object') return false;
  
  const response = data as Record<string, unknown>;
  return (
    typeof response.lat === 'number' &&
    typeof response.lng === 'number' &&
    typeof response.formatted_address === 'string'
  );
}