import React from 'react';
import { CheckCircle2, AlertCircle } from 'lucide-react';

export function BlogPost() {
  return (
    <article className="max-w-4xl mx-auto prose prose-lg">
      <h1 className="text-4xl font-bold mb-8 text-center">
        Finding the Best Dog Groomer Near Me: The Ultimate Guide
      </h1>

      <img
        src="https://images.unsplash.com/photo-1516734212186-a967f81ad0d7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80"
        alt="Dog being groomed"
        className="w-full h-[400px] object-cover rounded-lg mb-8"
      />

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Why Professional Dog Grooming Matters</h2>
        <p className="mb-4">
          Professional dog grooming is more than just making your furry friend look pretty. 
          Regular grooming is essential for your dog's health and well-being. It helps prevent 
          matting, skin issues, and allows early detection of potential health problems.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">How to Choose the Right Groomer</h2>
        <div className="space-y-4">
          <div className="flex items-start gap-3">
            <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold mb-2">Check Credentials and Experience</h3>
              <p>Look for certified groomers with proper training and experience with your dog's breed.</p>
            </div>
          </div>
          <div className="flex items-start gap-3">
            <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold mb-2">Read Reviews and Testimonials</h3>
              <p>Check online reviews and ask for references from other pet owners.</p>
            </div>
          </div>
          <div className="flex items-start gap-3">
            <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold mb-2">Visit the Facility</h3>
              <p>Ensure the grooming facility is clean, well-maintained, and has proper safety measures.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Red Flags to Watch Out For</h2>
        <div className="space-y-4">
          <div className="flex items-start gap-3">
            <AlertCircle className="w-6 h-6 text-red-500 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold mb-2">Unusually Low Prices</h3>
              <p>Extremely low prices might indicate inexperience or cut corners in service quality.</p>
            </div>
          </div>
          <div className="flex items-start gap-3">
            <AlertCircle className="w-6 h-6 text-red-500 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold mb-2">Reluctance to Answer Questions</h3>
              <p>A professional groomer should be transparent about their processes and policies.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Preparing Your Dog for Grooming</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>Brush your dog regularly between appointments</li>
          <li>Get your puppy used to being handled early</li>
          <li>Keep up with regular veterinary check-ups</li>
          <li>Communicate any special needs or health concerns to your groomer</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-4">Grooming Frequency Guidelines</h2>
        <div className="grid md:grid-cols-2 gap-6">
          <div className="bg-blue-50 p-6 rounded-lg">
            <h3 className="font-semibold mb-2">Short-Haired Breeds</h3>
            <p>Every 8-12 weeks for basic grooming</p>
          </div>
          <div className="bg-blue-50 p-6 rounded-lg">
            <h3 className="font-semibold mb-2">Long-Haired Breeds</h3>
            <p>Every 4-8 weeks for maintenance</p>
          </div>
        </div>
      </section>
    </article>
  );
}