import axios from 'axios';
import type { GeocodeResult } from '../../types/geocoding';
import { API_ENDPOINTS, ERROR_MESSAGES } from '../../utils/constants';
import { handleApiError } from '../../utils/apiUtils';
import { isValidZipCode, validateGeocodeResponse } from '../../utils/validation';

export async function geocodeZipCode(zipCode: string): Promise<GeocodeResult> {
  if (!isValidZipCode(zipCode)) {
    throw new Error(ERROR_MESSAGES.INVALID_ZIP);
  }

  try {
    const response = await axios.post<GeocodeResult>(
      API_ENDPOINTS.GEOCODE, 
      { zipCode: zipCode.trim() }
    );

    if (!validateGeocodeResponse(response.data)) {
      throw new Error(ERROR_MESSAGES.INVALID_RESPONSE);
    }

    return response.data;
  } catch (error) {
    throw handleApiError(error, ERROR_MESSAGES.GEOCODE_FAILED);
  }
}